//Call this function on  pageload
function init() {   
    var service = viveService.initiate();

     //Embedded Link
    service.URL = ""; 

    // This is the div id where the dashboard needs to be rendered.
    service.destination = ""; 

    // The filter paramerters/attributes should not contain any special characters and space between words.
    service.filters = {
        filters: {
            "StateName": ["Texas"]
        },
        customSecurity: {
            "StateName": ["Georgia"]
        }
    }

    service.header = false;
    service.generate();
}

var viveService = {
    initiate: function () {
        return this;
    },
    generate: function () {
        var host = (window.location.host == "") ? "null" : window.location.host;
        var filters = (this.filters == "") ? "false" : encodeURIComponent(JSON.stringify(this.filters));
        var html = '<object data="' + this.URL + '&header=' + this.header + '&requestfrom=' + host + '&filters=' + filters + '" style="border: 0;height:100%;width:100%"></object>';
        document.getElementById(this.destination).innerHTML = html;
    }
}
